$inter-font: 'Inter', sans-serif
$albra-semi-bold-font: 'Albra-semi',"Times New Roman", Times, serif

$color-grey-1: #F2F3F5
$color-grey-2: #D7DADE
$color-grey-3: #BFC1C4
$color-grey-4: #939395
$color-grey-5: #57575B
$color-grey-6: #3A3A3D

$color-yellow-1: #FFF5D8
$color-yellow-2: #FFEAB1
$color-yellow-3: #FFE089
$color-yellow-4: #FFCB3B
$color-yellow-5: #D0A52F
$color-yellow-6: #A07F23
$color-yellow-7: #715918

$color-violet-1: #E9E0FF
$color-violet-2: #D2C2FF
$color-violet-3: #BCA3FF
$color-violet-4: #8F66FF
$color-violet-5: #7652D8
$color-violet-6: #5C3DB1
$color-violet-7: #43298B

$color-turquoise-1: #DBF7F2
$color-turquoise-2: #B8EFE5
$color-turquoise-3: #94E7D8
$color-turquoise-4: #4DD7BE
$color-turquoise-5: #3EAC98
$color-turquoise-6: #2E8172
$color-turquoise-7: #1F564C

$color-red-1: #FFD9D9
$color-red-2: #FEB3B3
$color-red-3: #FE8C8C
$color-red-4: #FD4040
$color-red-5: #CF3333
$color-red-6: #A02626
$color-red-7: #721A1A

$tileWidth: 220px
$color-text: $color-grey-5
$color-primary: $color-violet-4
$color-white: #FFFFFF
$color-black: #1D1D1F

$radius-extra-small: .325rem
$radius-small: .625rem
$radius-medium: 1.25rem

$break22: 22rem
$break26: 26rem
$break30: 30rem
$break37: 37rem
$break48: 48rem
$break62: 62rem
$break64: 64rem
$break75: 75rem
$break92: 92rem
$break128: 128rem
