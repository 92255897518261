@use 'common'

.wrapper
	//

.list
	list-style-type: none
	padding: unset
	margin: unset

	display: flex
	justify-content: center
	gap: 1rem

.item
	display: flex

	&.is_circleBackground
		padding: 0.625rem
		background-color: common.$color-white
		border-radius: 50%

.link
	display: flex
	transition: transform 0.2s ease-out

	&:hover, &:focus-visible
		transform: scale(1.05)
