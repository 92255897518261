@use 'common'

$menuPadding: 1.2rem

.wrapper
	cursor: pointer
	position: relative
	padding-left: 1.5rem
	display: flex
	gap: 0.2rem
	align-items: center
	justify-self: end

	@media (min-width: common.$break37)
		column-gap: 0.5rem
		display: grid
		grid-template-columns: auto auto
		align-items: center

.iconWrapper
	width: 1.5rem
	height: 1.5rem

.site
	color: common.$color-grey-4
	font-weight: 600
	margin-top: 2px
	font-size: 0.8rem
	@media (min-width: common.$break37)
		font-size: 1rem

.siteMenu
	position: absolute
	font-weight: 600
	font-size: 1rem
	top: calc(100% + 0.5rem)
	left: $menuPadding
	background-color: common.$color-grey-1
	border-radius: common.$radius-small
	padding: 1rem $menuPadding
	width: 100%
	text-align: right
	transform: translateY(10px)
	transition-property: visibility, opacity, transform
	transition-duration: 0.3s
	transition-timing-function: ease-in-out
	visibility: hidden
	opacity: 0
	&IsVisible
		visibility: visible
		opacity: 1
		transform: translateY(0)

	&Item
		color: common.$color-grey-4
		margin-bottom: 0.5rem
		&:hover
			color: common.$color-violet-4
