@use 'common'

.wrapper
	+common.body1

	position: relative
	display: inline-flex
	text-align: center
	justify-content: center
	font-family: inherit
	font-weight: 400
	cursor: pointer
	border-radius: 2.5rem
	text-decoration: none
	overflow: hidden
	z-index: 0
	transition: color .3s ease-out

	&::before
		transition: background-color .3s ease-out

	&:focus
		outline: none
	&::before, &::after
		content: ''
		position: absolute
		border-radius: 2.5rem
		inset: 0
		overflow: hidden
		z-index: 0

	&:disabled
		cursor: not-allowed

	&.is_fullWidth
		width: 100%

	&.is_size_small
		padding: .625rem 2rem

	&.is_size_large
		padding: 1rem 3rem

		@media (min-width: common.$break48)
			padding: 1.5rem 4rem

	&.is_variant_primary
		color: #ffffff
		border: none

		&::before

			border-color: common.$color-primary
			background-color: common.$color-primary

		&:disabled
			&::before
				background-color: common.$color-grey-3

		&:hover
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						background-color: common.$color-violet-5

		&:focus-visible
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						background-color: common.$color-violet-6

	&.is_variant_secondary
		color: common.$color-primary
		background-color: transparent
		border: 1px solid common.$color-primary

		&::before
			background-color: transparent

		&:disabled
			&::before
				background-color: transparent

		&:hover
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						background-color: common.$color-violet-1

		&:focus-visible
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						background-color: common.$color-violet-2

	&.is_variant_terciary
		$backgroundColor: #1D1D1D
		--Buton-modifier-opacity: .2

		color: common.$color-white

		&::before
			background-color: rgba($backgroundColor, var(--Buton-modifier-opacity))
			backdrop-filter: blur(1.75rem)

		&:disabled
			&::before
				background-color: transparent

		&:hover
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						--Buton-modifier-opacity: .5

		&:focus-visible
			&:not(:disabled)
				&:not(.is_variant_text)
					&::before
						--Buton-modifier-opacity: .8

	&.is_variant_text
		color: inherit
		padding: 0
		background-color: transparent

		&::before
			border-color: transparent
			background-color: transparent

		&:hover,
		&:focus-visible
			&:not(:disabled)
				color: common.$color-primary

.content
	z-index: 1
