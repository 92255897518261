@use 'common'

$gap: 4rem

.wrapper
	padding-block: 2.5rem
	background-color: common.$color-grey-1

.main
	display: flex
	flex-direction: column
	align-items: center
	text-align: center
	gap: $gap
	column-gap: 12.5rem

	@media (min-width: common.$break48)
		flex-direction: row
		flex-wrap: wrap
		text-align: initial

.logo
	display: flex
	justify-content: center
	&Image
		//	
	@media (min-width: common.$break48)
		align-self: flex-start

.columns
	display: flex
	flex-wrap: wrap
	flex-direction: column
	gap: $gap

	@media (min-width: common.$break48)
		flex-direction: row
		flex-grow: 1
		gap: 4rem

.columnsList
	display: flex

	.columnList
		width: 10rem
		flex-shrink: 0

	@media not all and (min-width: common.$break48)
		flex-wrap: wrap
		justify-content: center

.column
	&Main
		display: flex
		flex-direction: column
		gap: 3rem
		align-self: center

		@media (min-width: common.$break48)
			align-self: flex-start

	&Title
		+common.body1(true)

	&List
		list-style-type: none
		padding: unset
		margin: unset

	&Item
		display: flex

		&:not(:first-child)
			margin-top: .6875rem

		&Link
			+common.body2
			color: common.$color-grey-4
			text-decoration: none
			transition: color 0.3s ease-out

			&:hover, &:focus-visible
				color: common.$color-violet-4

		&Label
			+common.body2
			color: common.$color-violet-4
			margin-left: .625rem

	@media (min-width: common.$break48)
		&:not(.is_branch)
			width: 14rem

.button
	@media (min-width: common.$break48)
		align-self: flex-start
		justify-self: flex-end

.bottomBar
	display: flex
	flex-direction: column
	gap: $gap
	margin-top: 4rem

	@media not all and (min-width: common.$break48)
		text-align: center

	@media (min-width: common.$break48)
		flex-direction: row-reverse
		justify-content: space-between
		align-items: flex-end
		flex-basis: 100%

.copyrights
	display: flex
	flex-direction: column
	gap: 1rem

	@media (min-width: common.$break62)
		flex-direction: initial
		gap: 1.625rem

.copyright
	+common.body2
	color: common.$color-grey-4
