@use 'common'

.wrapper
	position: fixed
	z-index: 10
	inset: 0
	transform: translateY(-100%)
	background-color: common.$color-grey-1
	min-height: 100vh
	min-height: -webkit-fill-available
	transition-property: transform
	transition-duration: 0.3s
	transition-timing-function: ease-out

	@media (min-width: common.$break48)
		display: none

	&.is_open
		transform: translateY(0)

.header
	display: flex
	justify-content: space-between
	padding-inline: 1rem
	padding-block: 1rem 1.625rem

.more,
.back,
.cross
	font-size: 1.25rem
	background-color: transparent
	outline: none
	border-style: none

.back,
.cross
	color: #1D1D1D

.back
	visibility: hidden
	opacity: 0
	transform: scaleX(-1)

	&.is_visible
		visibility: visible
		opacity: 1

.linksWrapper
	position: relative

.links
	position: absolute
	inset: 0
	visibility: visible
	opacity: 1
	transform: translateX(0)
	list-style-type: none
	margin: 0
	padding: 0 2.1875rem 3.75rem
	display: flex
	flex-direction: column
	gap: 1rem
	transition-property: opacity, visibility, transform
	transition-duration: 0.3s
	transition-timing-function: ease-out 

	&.is_hidden
		visibility: hidden
		opacity: 0
		transform: translateX(-100%)

	&.view_nested.is_hidden
		transform: translateX(100%)

.item
	//

.link
	display: flex
	align-items: center
	justify-content: space-between
	text-decoration: none
	color: #1D1D1D
	+common.heading4

	&.is_highlighted
		font-weight: 700
		color: common.$color-violet-5

	&:hover,
	&:focus
		text-decoration: underline

.chevron
	display: flex
	font-size: 1.25rem
	color: common.$color-primary
