@use '../variables'

=heading
	line-height: 1.2
	font-family: var(--font-headings)
	font-weight: 600
	margin-top: unset

=subtitle($isBold: false)
	line-height: 1.2
	font-family: var(--font-body)
	font-weight: 300

	@if $isBold
		font-weight: 700

	@media (min-width: variables.$break48)
		font-size: 1.5rem

		@if $isBold
			font-weight: 600

=bodyText($isBold: false)
	line-height: 1.4
	font-family: var(--font-body)

	@if $isBold
		font-weight: 700
	@else
		font-weight: 400

=heading1
	+heading
	font-size: 2.8125rem

	@media (min-width: variables.$break48)
		font-size: 4rem

=heading2
	+heading
	font-size: 2rem

	@media (min-width: variables.$break48)
		font-size: 3rem

=heading3
	+heading
	font-size: 1.75rem

	@media (min-width: variables.$break48)
		font-size: 2.5rem

=heading4
	+heading
	font-size: 1.625rem

	@media (min-width: variables.$break48)
		font-size: 2.25rem

=heading5
	+heading
	font-size: 1.5rem

	@media (min-width: variables.$break48)
		font-size: 1.625rem

=subtitle1($isBold: false)
	+subtitle($isBold)
	font-size: 1.125rem

	@media (min-width: variables.$break48)
		font-size: 1.5rem

=subtitle2($isBold: false)
	+subtitle($isBold)
	font-size: .9375rem

	@media (min-width: variables.$break48)
		font-size: 1.25rem

=body1($isBold: false)
	+bodyText($isBold)
	font-size: 1rem

	@media (min-width: variables.$break48)
		font-size: 1.125rem

=body2($isBold: false)
	+bodyText($isBold)
	font-size: .875rem

	@media (min-width: variables.$break48)
		font-size: 1rem

=smallHeadline($isBold: false)
	line-height: 1.2
	font-family: var(--font-body)
	font-weight: 600
	font-size: 1.25rem
