@use '../components/common'

.wrapper
  min-height: calc(100vh - 280px)
  display: flex
  align-items: center
  justify-content: center
  background-color: #fff

.content
  text-align: center
  max-width: 600px
  margin: 0 auto

.title
  font-size: 8rem
  font-weight: 700
  color: common.$color-primary
  line-height: 1
  margin: 0
  font-family: var(--font-headings)
  @media (max-width: common.$break48)
    font-size: 6rem

.subtitle
  font-size: 2rem
  color: common.$color-black
  margin: 1rem 0
  font-weight: 600
  font-family: var(--font-headings)

.text
  font-size: 1.2rem
  color: common.$color-grey-5
  margin: 1.5rem 0
  line-height: 1.5

.actions
  margin-top: 2rem