@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-chevron
		+normalizeSize(24, 24)
	&-cross
		+normalizeSize(20, 20)
	&-instagram
		+normalizeSize(41, 40)
	&-linkedin
		+normalizeSize(38, 37)
	&-sandwich
		+normalizeSize(32, 46)
	&-twitter
		+normalizeSize(40, 35)
	&-rainbowWave
		+normalizeSize(287, 17)
	&-pencilCircle
		+normalizeSize(100, 74)
	&-pencilUnderline
		+normalizeSize(354, 15)
	&-pointWithNoSpacing,
	&-plusCircle
		+normalizeSize(24, 24)
	&-at,
	&-graph,
	&-house,
	&-message,
	&-wavingPerson,
	&-telephone,
	&-point
		+normalizeSize(32, 32)
	&-arrowLifeCycle
		+normalizeSize(183, 103)
	&-arrowUp
		+normalizeSize(37, 70)
	&-dashedArrow
		+normalizeSize(156, 26)
	&-pencilCircle2
		+normalizeSize(143, 82)
	&-arrowLink
		+normalizeSize(34, 26)
