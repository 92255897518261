@use 'common'

$-break: common.$break75
$logoHeight: 46px

.headWrapper
	--Header-maxWidth: #{common.$break128}
	--Header-inlineSpacing: var(--Header-wrapper-inlineSpacing)

.bottomBorder
	display: none

	@media (min-width: common.$break48)
		display: block
		width: 100%
		width: calc(100% - 2 * var(--Header-inlineSpacing))
		max-width: calc(var(--Header-maxWidth) - 2 * var(--Header-inlineSpacing))
		height: 1px
		background-color: common.$color-grey-2
		margin-inline: auto

.wrapper
	max-width: var(--Header-maxWidth)
	margin: 0 auto
	padding-inline: var(--Header-inlineSpacing)

.wrapper
	background-color: var(--background-color)
	padding-block: 1.2rem var(--Header-wrapper-bottomSpacing)

	&::before
		content: ''
		display: block
		position: absolute
		z-index: -1
		width: 100%
		height: 100%
		top: 0
		left: 0
		background-color: common.$color-white

	&::after
		content: ''
		display: block

	&.no_padding_top
		padding-top: 0

.in
	display: grid
	grid-template-columns: 1fr 1fr 0.5fr
	column-gap: 0.1rem
	align-items: center
	@media (min-width: common.$break48)
		grid-template-columns: auto 1fr auto auto

	&::before
		content: ""
		display: block
		position: absolute
		bottom: 0
		background-color: common.$color-violet-4
		height: .0625rem
		width: var(--Header-activeLink-width)
		left: var(--Header-activeLink-offsetLeft, 50%)
		transform: translateX(-50%)
		transition-property: left, width, visibility, opacity
		transition-duration: 0.3s
		transition-timing-function: ease-in-out
		visibility: hidden
		opacity: 0

	.is_lineVisible &::before
		visibility: visible
		opacity: 1

.logo
	display: flex
	&Link
		display: flex

.title
	margin: 0

.links
	list-style-type: none
	display: flex
	flex-wrap: wrap
	justify-content: flex-end
	margin: 0 2rem
	font-size: 1rem
	padding: unset

	@media not all and (min-width: common.$break48)
		display: none

.item
	--HeaderNestedLinks-visibility: hidden
	--HeaderNestedLinks-opacity: 0
	--HeaderNestedLinks-transform: translate(-50%, -1.25rem)

	&:hover
		--HeaderNestedLinks-visibility: initial
		--HeaderNestedLinks-opacity: 1
		--HeaderNestedLinks-transform: translate(-50%, 0)
		.chevron
			transform: rotate(90deg) scaleX(-1)

.link
	display: inline-block
	padding: var(--Header-link-blockSpacing) 1rem
	text-decoration: none
	color: common.$color-grey-6
	transition-property: color
	transition-duration: 0.3s

	&:hover
		color: common.$color-violet-4

.linkIn
	display: flex
	align-items: center
	gap: .4375rem

.chevron
	display: flex
	font-size: 1.25rem
	transform: rotate(90deg)
	transition: transform 0.3s
	color: common.$color-primary

.button
	font-size: 1.125rem

	@media not all and (min-width: common.$break48)
		display: none

.sandwich
	font-size: 2.875rem
	background-color: transparent
	outline: none
	margin-left: auto
	max-width: 44px
	border-style: none
	text-align: right
	@media (min-width: common.$break48)
		display: none

.head
	@media (min-width: common.$break75)
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
	&Wrapper
	@media (min-width: common.$break75)
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		z-index: 2
		pointer-events: none
