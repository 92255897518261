@use 'common'

$image-width: 4.0625rem

.wrapper
	position: relative

.in
	visibility: var(--HeaderNestedLinks-visibility)
	opacity: var(--HeaderNestedLinks-opacity)
	transform: var(--HeaderNestedLinks-transform)
	position: absolute
	top: 0
	left: 50%
	width: 23.125rem
	transition-property: opacity, transform
	transition-duration: 0.2s
	transition-timing-function: ease-out
	padding-top: calc(var(--HeaderNestedLinks-linkToBorder-blockSpacing) + 1px)

.box
	padding: 2rem 1.625rem
	background-color: var(--background-color)
	box-shadow: 0 1.875rem 3.75rem 1.875rem rgba(0, 0, 0, 0.03)
	border-radius: .625rem
	display: flex
	flex-direction: column
	gap: .25rem

.item
	display: grid
	grid-template-columns: $image-width auto
	gap: 1.375rem
	padding: .625rem
	text-decoration: none
	align-items: center

	&:hover
		.title
			text-decoration: underline

.itemContent
	display: flex
	flex-direction: column
	gap: .125rem

.title
	font-size: 1.25rem
	font-weight: 600
	line-height: 1.2
	color: common.$color-grey-5

.description
	color: common.$color-grey-4
	font-weight: 400
	line-height: 1.2

.image
	display: flex
	position: relative
	max-width: $image-width

	img
		width: 100%
		height: 100%
		object-fit: contain

.wrapper
	.in
		position: absolute
		top: 0
		left: 50%
		width: unset
		transition-property: opacity, transform
		transition-duration: 0.2s
		transition-timing-function: ease-out
		padding-top: calc(var(--HeaderNestedLinks-linkToBorder-blockSpacing) + 1px)
		padding-inline: var(--common-edge-gap)
		max-width: calc(var(--common-content-width-normal) + 2 * var(--common-edge-gap))
		margin-inline: auto
		visibility: var(--HeaderNestedLinks-visibility)
		opacity: var(--HeaderNestedLinks-opacity)
		transform: var(--HeaderNestedLinks-transform)

	.box
		padding: 2rem 1.625rem
		background-color: var(--background-color)
		box-shadow: 0 1.875rem 3.75rem 1.875rem rgba(0, 0, 0, 0.03)
		border-radius: .625rem
		gap: .25rem
	.list
		list-style: none
		padding: 0
		margin: 0

		display: grid
		column-gap: 2.8125rem

		@media (min-width: common.$break37)
			grid-template-columns: 1fr 1fr

		@media (min-width: common.$break48)
			grid-template-columns: minmax(20rem, 1fr) minmax(20rem, 1fr)

		@media (min-width: common.$break92)
			grid-template-columns: minmax(20rem, 1fr) minmax(20rem, 1fr) minmax(20rem, 1fr)
